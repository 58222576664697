
import { defineComponent, onMounted, reactive } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import { Field } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/core/services/ApiService";

import { useToast } from "vue-toastification";
import { ref } from "yup";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import moment from "moment";
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Multiselect from "@vueform/multiselect";
import { useRoute } from "vue-router";
import { tap } from "lodash";

export default defineComponent({
  name: "kt-widget-11",
  components: {
    Field,
    Loading,
    Multiselect,
  },
  props: {
    widgetClasses: String,
  },

  data() {
    return {
      tableData: [
        {
          createTime: "",
          creatorId: "",
          updateTime: "",
          editorId: "",
          isDelete: false,
          deleteBy: "",
          deleteTime: "",
          mappingType: "",
          mappingDate: "",
          nfcId: "",
          batchId: "",
          purchaseId: "",
          merchantId: "",
          creatorName: "",
          totalMapping: 0,
          _id: "",
        },
      ],
      resume: {
        createTime: "",
        creatorId: "",
        updateTime: "",
        editorId: "",
        isDelete: false,
        deleteBy: "",
        deleteTime: "",
        purchaseDate: "",
        merchantId: "",
        totalCard: 0,
        totalCardFailed: 0,
        blockedMapping: false,
        _id: "",
        merchantName: "",
        startMapping: "",
        lastMapping: "",
        totalCardActive: 0,
        totalCardSuccess: 0,
        totalCardRemains: 0,
      },
      listCategory: [],
      listStatus: [],
      isLoading: false,
      fullPage: true,
      isColor: "#007BFF",
      purchaseId: "",
      isBackgroundColor: "#A3A3A3",
      isOpacity: 0.5,
      uploadData: "",
      batchId: "",
      tap: {
        batchId: "",
        mappingDate: "",
        purchaseId: "",
        nfcId: "",
      },

      listKartu: [
        {
          nfcId: "",
        },
      ],

      tanggalMaping: "",
      purchaseDate: "",
      role: "",
      paging: {
        page: 0,
        totalPages: 0,
        size: 10,
        totalElements: 0,
        perPageOptions: [10, 25, 50, 100],
      },

      name: "",
      categoryId: "",
      nfc: "",
    };
  },

  beforeMount() {
    // store.dispatch(Actions.VERIFY_AUTH);
    this.role = JSON.parse(localStorage.getItem("user_account")!).role;
    const route = useRoute();
    this.batchId = "" + route.params.batch;
    this.purchaseId = "" + route.params.id;
    console.log(this.batchId);
    this.getResume();
    this.getData(this.paging.size, this.paging.page);
    setCurrentPageBreadcrumbs("Data Produk", ["Data"]);
  },

  computed: {
    to() {
      let highBound = this.from + this.paging.size;
      if (this.paging.totalElements < highBound) {
        highBound = this.paging.totalElements;
      }
      return highBound;
    },
    from() {
      return this.paging.size * this.paging.page;
    },
  },

  methods: {
    handlefileexcel(event) {
      const toast = useToast();
      const val = event.target.files[0].name;
      if (event.target.files[0]) {
        const myArray = val.split(".");
        if (myArray[1] == "xlsx" || myArray[1] == "xls") {
          this.uploadData = event.target.files[0];
        } else {
          return toast.error("File Hanya .xlsx");
        }
      } else {
        toast.error("File Kosong");
      }
    },

    uploadDataExcel() {
      const toast = useToast();
      let formData = new FormData();
      formData.append("file", this.uploadData);
      var office = "";
      if (this.role == "ADMIN_SCHOOL") {
        office = "";
      } else {
        office = "office/";
      }
      ApiService.postWithData(
        `crmv2/main_card/card_stock/${office}mapping/upload?batchId=${
          this.batchId
        }&mappingDate=${moment().format("YYYY-MM-DD")}&purchaseId=${
          this.purchaseId
        }`,
        formData
      )
        .then((res) => {
          toast.success("Berhasil Upload Dokumen");

          this.isLoading = false;
        })
        .catch((e) => {
          this.isLoading = false;
          toast.error(e.response.data.detail);
        });
    },
    getData(size, page) {
      const route = useRoute();
      var itemId = this.batchId;

      this.isLoading = true;

      let search = "";
      let valcategoryId = "";
      if (this.categoryId) {
        valcategoryId = this.categoryId;
      }
      if (this.name) {
        search = this.name;
      }
      var office = "";
      if (this.role == "ADMIN_SCHOOL") {
        office = "";
      } else {
        office = "office/";
      }
      ApiService.getWithoutSlug(
        "crmv2/main_card/card_stock/" +
          office +
          "stock/all?" +
          "page=" +
          page +
          "&size=" +
          size +
          "&sort_by=createTime&dir=1&batchId=" +
          itemId
      )
        .then(({ data }) => {
          this.tableData = data.content;
          this.tanggalMaping = moment(data.content[0].mappingDate).format(
            "YYYY-MM-DD LT"
          );
          this.purchaseDate = moment(data.content[0].purchaseDate).format(
            "YYYY-MM-DD"
          );
          this.paging.page = data.page;
          this.paging.size = data.size;
          this.paging.totalElements = data.totalElements;
          this.paging.totalPages = data.totalPages;
          this.isLoading = false;
        })
        .catch(({ response }) => {
          this.isLoading = false;
        });
    },
    getResume() {
      const route = useRoute();
      var itemId = "" + route.params.id;
      var office = "";
      if (this.role == "ADMIN_SCHOOL") {
        office = "";
      } else {
        office = "office/";
      }
      ApiService.getWithoutSlug(
        "crmv2/main_card/card_purchasing/" + office + itemId
      )
        .then((resp) => {
          this.resume = resp.data;
          console.log(this.resume)
          this.isLoading = false;
        })
        .catch(({ response }) => {
          this.isLoading = false;
        });
    },

    pilihKategori(event) {
      // console.log(event.target.value);
      console.log(event);
      // this.schoolId = event;
      if (event) {
        this.categoryId = event;
      } else {
        this.categoryId = "";
      }
      // this.getQtyStudent();
      localStorage.setItem("sekolahId", JSON.stringify(event));
      this.getData(this.paging.size, this.paging.page);
    },

    handleDelete(item) {
      const toast = useToast();
      var office = "";
      if (this.role == "ADMIN_SCHOOL") {
        office = "";
      } else {
        office = "office/";
      }
      ApiService.delete(
        `crmv2/main_card/card_stock/${office}mapping/${item}?batchId=${this.batchId}`
      )
        .then((res) => {
          this.tableData = this.tableData.filter(
            (person) => person.nfcId != item
          );

          toast.success("Berhasil Menghapus");
        })
        .catch((e) => {
          toast.error("Gagal Menghapus");
        });
    },
    deleteRow(item) {
      const toast = useToast();
      this.isLoading = true;

      // ApiService.delete(
      //   "crmv2/main_order/product/" + item._id
      // )
      //   .then((res) => {
      //     toast.success("Hapus Berhasil");

      //     this.getData(this.paging.size, this.paging.page);
      //     this.isLoading = false;
      //   })

      //   .catch((error) => {
      //     toast.error("Gagal Hapus");
      //     this.isLoading = false;
      //   });
    },

    range(min, max) {
      const arr: any[] = [];
      for (let i = min; i <= max; i++) {
        arr.push(i);
      }
      return arr;
    },

    changePage(item) {
      const page = item - 1;
      this.getData(this.paging.size, page);
    },
    nextPage() {
      const page = this.paging.page + 1;
      console.log(page);
      this.getData(this.paging.size, page);
    },
    prevPage() {
      const page = this.paging.page - 1;
      this.getData(this.paging.size, page);
    },
    changePageSize() {
      const page = this.paging.page;
      this.getData(this.paging.size, page);
    },

    changeModal() {},

    dateTime(val) {
      if (val) {
        return moment(val).format("YYYY-MM-DD");
      } else {
        return "-";
      }
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
});
